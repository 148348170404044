import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ConfirmedPage = () => {
    return (
        <Layout>
            <SEO title="Prime Ventures Alliance" keywords={[`investment`, `opportunity`]} />

            <div class="section2 container text-center">
                <h1 class="heading-1">Thank you for your interest in PVA</h1>
                <p class="para-1">We have sent you an email to confirm your interest.</p>
            </div>
        </Layout>
    );
};

export default ConfirmedPage;
